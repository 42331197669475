<template>
  <div>
    <template>
      <v-container>
        <!-- One Logo / Slogan -->
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 10vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
        </div>
        <div style="height: 65vh">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <form style="width: 80%" @submit.prevent>
              <div class="mb-5">
                <p
                  class="
                    white--text
                    text-center text-uppercase
                    font-weight-bold
                  "
                >
                  We have sent a verify code to:
                  <span
                    :class="`d-block`"
                    :style="`color: ${getActionColor};`"
                    >{{ getRegisterEmail }}</span
                  >
                </p>
              </div>

              <div class="mb-3">
                <input
                  ref="VERIFYCODE"
                  type="text"
                  :style="`
                  background-color: transparent;
                  color: white;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;
                `"
                  placeholder="Please enter verify code"
                  v-model.trim="payload.user.verifyCode"
                  class="form-control py-2 text-center"
                  maxlength="6"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />
              </div>

              <div class="mb-5">
                <button
                  class="text-white py-1"
                  :style="`
                    background-color: ${
                      validSubmission ? 'grey' : getAccentColor
                    };
                    border: none;
                    width: 100%;
                    border-radius: 30px;
                    font-weight: 500;
                    `"
                  @click="traverse('user_profile')"
                  :disabled="validSubmission"
                >
                  <span style="text-transform: uppercase; font-size: 1.5em">
                    next
                  </span>
                </button>
              </div>
              <div>
                <p
                  class="text-center text-uppercase fst-italic white--text"
                  style="font-size: 0.6em"
                >
                  (if you haven't received a verify code,<br />please wait 2-3
                  minutes)
                </p>
              </div>
              <div style="height: 5vh"></div>

              <div class="mb-5 text-center">
                <button
                  style="
                    background-color: transparent;
                    color: white;
                    border: none;
                  "
                  @click="traverse('resubmit_email')"
                >
                  <span style="text-transform: uppercase; font-size: 1.2em"
                    >resend verify code?</span
                  >
                </button>
              </div>
            </form>
          </div>
        </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Toast } from "vant";

export default {
  name: "register",
  data() {
    return {
      response: {},
      payload: {
        user: {
          verifyCode: "",
        },
      },
    };
  },
  created() {},
  methods: {
    ...mapActions(["_setLoginState"]),
    /* API Calls */
    // GET
    // POST
    /* Main */
    traverse(action) {
      switch (action) {
        case "resubmit_email":
          this._setLoginState(false);
          this.$router.replace({ name: "login" });
          break;
        case "user_profile":
          //this.$router.push({ name: "complete_profile" });
          if (this.payload.user.verifyCode.length < 6) {
            this.$showTips({ msg: "Please enter 6 digits verify." });
            // Toast.fail("Please enter 6 digits verify.");
            this.$refs.VERIFYCODE.focus();
            return;
          }
          this.showLoading("Verifying...");
          this.$api.user
            .verificationCode({
              code: this.payload.user.verifyCode,
              email: this.getRegisterEmail,
            })
            .then((res) => {
              this.hideLoading();
              if (res.data.code === 200) {
                if (this.$route.params.type === "forgot") {
                  this.$router.push({
                    name: "editPassword",
                    params: {
                      code: this.payload.user.verifyCode,
                      email: this.$route.params.email,
                    },
                  });
                } else {
                  this.$router.push({ name: "complete_profile" });
                }
              } else {
                this.$showTips({ msg: res.data.message });
                // Toast.fail(res.data.message);
              }
            })
            .catch((res) => {
              console.log(res);
              this.hideLoading();
              this.$showTips({ msg: res.data.message });
              // Toast.fail(res.data.message);
            });
          break;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getMainColor",
      "getAccentColor",
      "getActionColor",
      "getRegisterEmail",
    ]),
    verifyCodeSet() {
      return this.payload.user.verifyCode.length > 0 ? true : false;
    },
    validSubmission() {
      return this.verifyCodeSet ? false : true;
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: white;
  text-align: center;
}
</style>
